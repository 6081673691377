// i18next-extract-mark-ns-start booking_form
import * as React from "react"
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store/store'
import { I18nextContext, Trans, useTranslation, Link } from 'gatsby-plugin-react-i18next'

import { faFileLines, faCreditCard, faLocationDot, faWarning, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from 'luxon'
import PageSection from "../page-section"
import { MeetingPoint, Notice } from "../../models/tour.model"
import FormContainer from "../form/form-container"
import FormBody from "../form/form-body"
import FormFooter from "../form/form-footer"

export interface BookingFormInitState {
    boatId: string,
    boatName: string,
    tourDatetime: Date,
    meetingPoint?: MeetingPoint,
    availability: number,
    notice?: Notice,
    minTickets?: number,
    price?: number,
    projectId?: string,
    depositPerTicket?: number,
    ticketsPerOrder?: number,
}

const BookingFormSection = ({ state }: { state?: BookingFormInitState }) => {

    const tourIsPayable = state?.price > 0 ?? false;

    const bookingFormElement = useRef<HTMLFormElement>();
    const localeContext = React.useContext(I18nextContext);

    const dispatch = useDispatch<Dispatch>();

    const { t } = useTranslation();

    const bookingState = useSelector((state: RootState) => state.booking)

    const formValues = {
        boatName: bookingState?.boatName ?? '',
        tourDatetime: bookingState?.tourDateTime?.setLocale(localeContext.language == "ua" ? "ru" : localeContext.language)?.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) ?? '',
        maxTicketsAmount: Math.min(bookingState?.ticketsPerOrder, bookingState?.ticketsLeft) ?? 6,
    }

    const totalDepositAmount = tourIsPayable ? bookingState.ticketsOrdered * state.price : bookingState.ticketsOrdered * bookingState.depositPerTicket;

    useEffect(() => {
        const convertedTourDate = DateTime.fromJSDate(state.tourDatetime);
        dispatch.booking.initForm({ boatId: state.boatId, boatName: state.boatName, tourDateTime: convertedTourDate, ticketsLeft: state.availability, translate: t, price: state.price, projectId: state.projectId, depositPerTicket: state.depositPerTicket, ticketsPerOrder: state.ticketsPerOrder, });
        dispatch.booking.fetchPaymentMethods();
    }, [])

    const onSubmit = () => {
        dispatch.booking.submitForm({ formElement: bookingFormElement?.current, language: localeContext.language });
    };

    const onTicketsOrderedChange = (e) => dispatch.booking.setTickets(e.target.value);

    const gotoExternalPage = (pageUrl: string) => {
        if (typeof window !== `undefined`) window.open(pageUrl, '_blank');
    }


    const displayTourNotice = dispatch.toursCalendar.displayTourNotice;

    const requiredErrorNotice = <div className="invalid-feedback">
        <Trans>Proszę uzupełnić wymagane pole</Trans>.
    </div>


    return (
        <PageSection className="bg-white">

            <FormContainer title={t("Formularz rezerwacji")}>

                <FormBody>
                    <form ref={bookingFormElement} className="row g-3 needs-validation" noValidate>

                        <label htmlFor="boatName" className="col-form-label col-sm-4   "><Trans>Atrakcja</Trans></label>
                        <div className="col-sm-8">
                            <input value={formValues.boatName} type="text" className="form-control" id="boatName" placeholder="" required disabled />
                        </div>

                        <label htmlFor="tourDatetime" className="col-form-label col-sm-4   "><Trans>Termin</Trans></label>
                        <div className="col-sm-8">
                            <input value={formValues.tourDatetime} type="text" className="form-control" id="tourDatetime" placeholder="" required disabled />
                        </div>

                        {(state?.meetingPoint) && <><label htmlFor="meetingPoint" className="col-form-label col-sm-4   "><Trans>Miejsce startu</Trans></label>
                            <div className="col-sm-8">
                                <div className="input-group">
                                    <input value={state.meetingPoint.name} type="text" className="form-control" id="meetingPoint" placeholder="" disabled />
                                    <button onClick={() => gotoExternalPage(state.meetingPoint.pinpointUrl)} className="btn  btn-outline-light" type="button"><FontAwesomeIcon icon={faLocationDot} /></button>
                                </div>
                            </div></>
                        }

                        {(state?.notice) && <><label htmlFor="notice" className="col-form-label col-sm-4   "><Trans>Opis</Trans> <FontAwesomeIcon icon={faInfoCircle} /></label>
                            <div className="col-sm-8">

                                <button onClick={() => displayTourNotice(state.notice)} className="btn  btn-outline-light" type="button"><Trans>Czytaj opis</Trans></button>

                            </div></>
                        }

                        <hr className="mt-5 mb-4"></hr>

                        <label htmlFor="ticketsAmount" className="col-form-label col-sm-4  "><Trans>Ilość osób</Trans></label>
                        <div className="col-sm-8">
                            <input onChange={onTicketsOrderedChange} type="number" className="form-control" id="ticketsAmount" placeholder="" aria-describedby="ticketsAmountHelp" min={state?.minTickets ?? 1} max={formValues.maxTicketsAmount} required />
                            {requiredErrorNotice}
                            <div id="ticketsAmountHelp" className="form-text">{state?.minTickets && `Minimalne zamówienie to ${state?.minTickets}. `}<Trans>Maksymalna dostępna ilość to</Trans> {formValues.maxTicketsAmount}.</div>
                        </div>

                        <label htmlFor="fullName" className="col-form-label col-sm-4   "><Trans>Imię i Nazwisko</Trans></label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="fullName" placeholder="" aria-describedby="fullNameHelp" required />
                            {requiredErrorNotice}
                            <div id="fullNameHelp" className="form-text"><Trans>Dane osoby rezerwującej</Trans></div>
                        </div>

                        <label htmlFor="mail" className="col-form-label col-sm-4   "><Trans>Email</Trans></label>
                        <div className="col-sm-8">
                            <input type="email" className="form-control" id="mail" placeholder="" aria-describedby="mailHelp" required />
                            {requiredErrorNotice}
                            <div id="mailHelp" className="form-text"><Trans>Na ten adres wyślemy potwierdzenie rezerwacji oraz link do ew. rezygnacji</Trans></div>
                        </div>

                        <label htmlFor="phone" className="col-form-label col-sm-4  "><Trans>Telefon</Trans></label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="phone" placeholder="" aria-describedby="phoneHelp" required />
                            {requiredErrorNotice}
                            <div id="phoneHelp" className="form-text"><Trans>Numer do kontaktu w razie nagłych zmian w Twojej rezerwacji</Trans></div>
                        </div>

                        <div className="py-2"></div>

                        <label htmlFor="paymentMethod" className="col-form-label col-sm-4  "><Trans>Metoda płatności</Trans></label>
                        <div className="col-sm-8">
                            <select id="paymentMethod" className="form-select form-control" aria-label="Default select example" aria-describedby="paymentMethodHelp" required>
                                {tourIsPayable ? <Trans><option value="">Metoda płatności</option></Trans> : <Trans><option value="">Metoda płatności kaucji</option></Trans>}
                            </select>
                            {requiredErrorNotice}
                            <div id="paymentMethodHelp" className="form-text"> {tourIsPayable ? <Trans>Rezerwacha jest przedpłacona w całości. Wniesioną opłatę zwrócimy Tobie w całości jeśli zrezygnujesz najpóźniej do 24 godzin przed rezerwowanym terminem</Trans> : <Trans>Do rezerwacji wymagamy przedpłacenia kaucji. Kaucję zwrócimy Tobie w całości na drugi dzień po zarezerwowanym terminie, lub jeśli zrezygnujesz najpóźniej do 24 godzin przed zarezerwowanym terminem</Trans>} </div>
                        </div>

                        <hr className="my-4"></hr>

                        <label htmlFor="legal" className="col-form-label col-sm-4  "><Trans>Zgody</Trans></label>
                        <div className="col-sm-8">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="check1" required />
                                <label className="form-check-label" htmlFor="check1">
                                    <Trans>Akceptuję <Link target="_blank" to={tourIsPayable ? "/regulaminy/platne" : "/regulaminy/"}>regulamin</Link></Trans>
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="check2" required />
                                <label className="form-check-label" htmlFor="check2">
                                    <Trans>Akceptuję <Link target="_blank" to="/polityka-prywatnosci">politykę prywatności</Link> i zgadzam się na przetwarzanie moich danych osobowych</Trans>
                                </label>
                            </div>
                            {!tourIsPayable && <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="check3" aria-describedby="check3Help" required />
                                <label className="form-check-label" htmlFor="check3">
                                    <Trans>Zgadzam się na publikację mojego wizerunku w celach promocji projektu</Trans>
                                </label>
                                <div id="check3Help" className="form-text"><Trans>Przygotujemy i opublikujemy relację fotograficzną w której możesz się znaleźć</Trans></div>
                            </div>}
                        </div>


                    </form>
                </FormBody>

                <FormFooter>
                    <div className="container-fluid p-0">
                        <div className="row align-items-center">
                            <div className="col text-center h-100">
                                {totalDepositAmount > 0 && (<>{tourIsPayable ? <Trans>Do zapłaty</Trans> : <Trans>Kaucja zwrotna</Trans>}: <h4 className="pt-1">{totalDepositAmount}zł</h4></>)}
                            </div>
                            <div className="d-sm-none pb-4"></div>
                            <div className="col text-end text-center-sm">
                                <button onClick={onSubmit} type="button" className="btn btn-secondary btn-lg"><FontAwesomeIcon icon={faCreditCard} className="pe-2" /><Trans>Do płatności</Trans></button>
                            </div>
                        </div>
                    </div>
                </FormFooter>

            </FormContainer>

        </PageSection >
    )
}

export default BookingFormSection