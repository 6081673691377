// i18next-extract-mark-ns-start booking_page_header
import { Trans } from "gatsby-plugin-react-i18next"
import * as React from "react"
import PageSection from "../page-section"

const BookingHeaderSection = () => {
    return (
        <PageSection>
            <h1 className="pb-3"><Trans>Rezerwacja</Trans></h1>
            <p>
                <Trans>Prosimy o wypełnienie formularza rezerwacji. Potwierdzenie rezerwacji otrzymasz mailem</Trans>.
            </p>
            <h4 className="py-3"><Trans>Szanujemy Twoją prywatność</Trans></h4>
            <p>
                <Trans>Bez obaw. Twoje dane osobowe będą wykorzystane wyłącznie w celu realizacji zamówienia</Trans>.
            </p>
        </PageSection >
    )
}

export default BookingHeaderSection