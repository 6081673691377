// i18next-extract-mark-ns-start booking_page
import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BookingHeaderSection from "../components/booking/header-section"
import BookingFormSection, { BookingFormInitState } from "../components/booking/form-section"
import { WindowLocation } from '@reach/router'
import { useTranslation } from "react-i18next"

const BookingPage = ({ location }: { location: WindowLocation }) => {

  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t("Rezerwacja")} />
      <BookingHeaderSection />
      <BookingFormSection state={location?.state as BookingFormInitState} />
    </Layout>
  )
}

export default BookingPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar", "booking_page", "booking_page_header", "booking_form", "booking_alerts"]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;